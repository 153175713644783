import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { ICurrentWeather } from "./weather.models";

export const weatherApi = createApi({
  reducerPath: "weather",
  baseQuery: fetchBaseQuery({ baseUrl: "https://test2.alexxxp.ru/weather" }),
  endpoints: (builder) => ({
    getCurrentWeather: builder.query<ICurrentWeather, string>({
      query: (search: string) => ({
        url: search,
        // params: {
        //   key: 'f65af894f8f34de4908125332221408',
        //   q: search,
        //   lang: 'ru'
        // }
      }),
    }),
    getLocationByIP: builder.query<ICurrentWeather, string>({
      query: (search: string) => ({
        url: "/ip.json",
        params: {
          key: "f65af894f8f34de4908125332221408",
          q: search,
          lang: "ru",
        },
      }),
    }),
  }),
});

export const { useGetCurrentWeatherQuery, useGetLocationByIPQuery } =
  weatherApi;
